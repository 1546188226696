<template>
  <svg :height="fullHeight" :viewBox="viewBox" xmlns="http://www.w3.org/2000/svg">

    <!-- Base color -->
    <rect :width="fullWidth" :height="fullHeight" :style="baseStyle"/>

    <!-- Inner colored field for mon -->
    <rect v-if="rank > 0 && rank < 6" x="0" :y="fullHeight / 4" :width="fullWidth" :height="fullHeight / 2" :style="monStyle"/>

    <!-- Yellow stripes for 1-5 dan -->
    <rect v-if="rank >= 11 && rank <= 15" :x="stripeWidth" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>
    <rect v-if="rank >= 12 && rank <= 15" :x="stripeWidth * 3" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>
    <rect v-if="rank >= 13 && rank <= 15" :x="stripeWidth * 5" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>
    <rect v-if="rank >= 14 && rank <= 15" :x="stripeWidth * 7" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>
    <rect v-if="rank === 15" :x="stripeWidth * 9" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>

    <!-- Red fields for 6-7 dan -->
    <rect v-if="rank >= 16 && rank <= 17" :x="fullWidth / 5" :width="fullWidth/5" :height="fullHeight" :style="redFill"/>
    <rect v-if="rank >= 16 && rank <= 17" :x="fullWidth * 3 / 5" :width="fullWidth/5" :height="fullHeight" :style="redFill"/>

    <!-- Number for 6-10 dan -->
    <text v-if="rank >= 16" :x="5" y="16" fill="yellow">{{ rank - 10 }}</text>
  </svg>
</template>

<script>
export default {
  props: {
    rank: Number
  },
  data() {
    return {
      fullWidth: 100,
      fullHeight: 20,
      stripeWidth: 5,
      yellow: 'yellow',
      orange: 'orange',
      green: 'green',
      blue: 'blue',
      brown: 'rgb(109,65,47)',
      red: 'rgb(182,0,0)'
    }
  },
  computed: {
    viewBox() {
      return `0 0 ${this.fullWidth} ${this.fullHeight}`
    },
    baseStyle() {
      return this.styleWithFill(this.baseColor)
    },
    monStyle() {
      return this.styleWithFill(this.monColor)
    },
    yellowFill() {
      return this.styleWithFill(this.yellow)
    },
    redFill() {
      return this.styleWithFill(this.red)
    },
    baseColor() {
      let rank = this.rank
      switch(rank) {
      case 6: return this.yellow
      case 7: return this.orange
      case 8: return this.green
      case 9: return this.blue
      case 10: return this.brown
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
        return 'black'
      case 18:
      case 19:
      case 20:
        return this.red
      default: return 'white'
      }
    },
    monColor() {
      switch(this.rank) {
      case 1: return this.yellow
      case 2: return this.orange
      case 3: return this.green
      case 4: return this.blue
      case 5: return this.brown
      default: return 'white'
      }
    }
  },
  methods: {
    styleWithFill(color) {
      return `fill:${color};stroke-width:1;stroke:black`
    }
  }
}
</script>

/**
Rank will internally be represented by a number.
| value | rank name | color       | japanese name |
|-------+-----------+-------------+---------------|
|     0 | No rank   | vitt        | rokkyu        |
|     1 | 5 mon     | gul-vitt    |               |
|     2 | 4 mon     | orange-vitt |               |
|     3 | 3 mon     | grön-vitt   |               |
|     4 | 2 mon     | blå-vitt    |               |
|     5 | 1 mon     | brun-vitt   |               |
|     6 | 5 kyu     | gult        | gokyu         |
|     7 | 4 kyu     | orange      | yonkyu        |
|     8 | 3 kyu     | grönt       | sankyu        |
|     9 | 2 kyu     | blått       | nikyu         |
|    10 | 1 kyu     | brunt       | ikkyu         |
|    11 | 1 dan     | svart       | Shodan        |
|    12 | 2 dan     | svart       | Nidan         |
|    13 | 3 dan     | svart       | Sandan        |
|    14 | 4 dan     | svart       | Yondan        |
|    15 | 5 dan     | svart       | Godan         |
|    16 | 6 dan     | röd-svart   | Rokudan       |
|    17 | 7 dan     | röd-svart   | Shichidan     |
|    18 | 8 dan     | röd-svart   | Hachidan      |
|    19 | 9 dan     | rött        | Kudan         |
|    20 | 10 dan    | rött        | Judan         |
*/
